import React from "react";
import "./ViewSimilar.css";
import similar1 from "../../Assets/tranding_man1.webp";
import similar2 from "../../Assets/tranding_man2.webp";
import similar3 from "../../Assets/tranding_man3.webp";
import similar4 from "../../Assets/tranding_man4.webp";

const ViewSimilar = () => {
  return (
    <div className="view-similar-container">
      <h3 className="section-heading">View Similar</h3>
      <div className="similar-grid">
        <div className="similar-item">
          <img src={similar1} alt="Product 1" />
          <div className="product-details">
            <p className="product-name">Blue Digital Floral Sharara Suit Set</p>
            <p className="product-price">Rs. 4,999.00</p>
          </div>
        </div>
        <div className="similar-item">
          <img src={similar2} alt="Product 2" />
          <div className="product-details">
            <p className="product-name">Black Digital Floral Sharara Suit Set</p>
            <p className="product-price">Rs. 4,999.00</p>
          </div>
        </div>
        <div className="similar-item">
          <img src={similar3} alt="Product 3" />
          <div className="product-details">
            <p className="product-name">Dusty Rose Silk Kurta Suit Set</p>
            <p className="product-price">Rs. 4,999.00</p>
          </div>
        </div>
        <div className="similar-item">
          <img src={similar4} alt="Product 4" />
          <div className="product-details">
            <p className="product-name">Green Cutwork Silk Kurta Suit Set</p>
            <p className="product-price">Rs. 4,999.00</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewSimilar;
