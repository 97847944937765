import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import Layout from "../Layout/Layout";
import Footer from "../Layout/Footer";
import "./ContactUs.css";

const ContactUs = () => {
    const location = useLocation();
    const [cartItems, setCartItems] = useState(location.state?.cartItems || []);

  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems} isNavbar={true}>
      <Container fluid className="contact-us-container">
        <h1 className="text-center mb-4">Contact Us</h1>

        <p>
          For any order related query, please reach out to us on 
          <strong> 7021307984</strong>, <br /> Monday - Friday from 10:30am - 6:30pm 
          Or you can drop us an email on <a href="mailto:info@tarunika.in">info@tarunika.in</a>
        </p>
        <p>
          Our customer service representative will get back to you as soon as possible.
        </p>
        <p>
          For partner/business inquiries, submit your enquiry on <a href="mailto:info@tarunika.in">info@tarunika.in</a>
        </p>
      </Container>

      <Footer showBothDivs={false} />
    </Layout>
  );
};

export default ContactUs;
