import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons";
import "./Footer.css";
import Footer_logo from "../../Assets/gold.png";

export const Footer = ({ showBothDivs }) => {

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  
  return (
    <div className="Footer-main">
      {showBothDivs ? (
        <>
          <div className="content-footer">
            <div className="footer-logo">
              <img src={Footer_logo} alt="logo" width={154}/>
            </div>

            <div className="footer-nav">
              <ul className="footer-nav-menu">
                <NavLink to="/" className="footer-navline1" onClick={scrollToTop}>HOME</NavLink>
                <NavLink to="/men" className="footer-navline1" onClick={scrollToTop}>MEN</NavLink>
                <NavLink to="/women" className="footer-navline2" onClick={scrollToTop}>WOMEN</NavLink>
                <NavLink to="/productlist" className="footer-navline1" onClick={scrollToTop}>PRODUCTS</NavLink>
              </ul>
            </div>

            <div className="footer-social-media">
              <FontAwesomeIcon icon={faFacebook}  />
              <FontAwesomeIcon icon={faInstagram}  />
              <FontAwesomeIcon icon={faTwitter}  />
            </div>

            <div className="footer-policies">
              <ul className="footer-policies-menu">
                <NavLink to="/privacy-policy" className="footer-policy-link" onClick={scrollToTop}>Privacy Policy</NavLink>
                <NavLink to="/return-refund-policy" className="footer-policy-link" onClick={scrollToTop}>Return & Refund Policy</NavLink>
                <NavLink to="/terms-conditions" className="footer-policy-link" onClick={scrollToTop}>Terms and Conditions</NavLink>
                <NavLink to="/shipping-policy" className="footer-policy-link" onClick={scrollToTop}>Shipping Policy</NavLink>
                <NavLink to="/contact-us" className="footer-policy-link" onClick={scrollToTop}>Contact Us</NavLink>
              </ul>
            </div>

            <div className="end-footer">
              <p>&copy; 2024 Tarunika. All Rights Reserved</p>
            </div>
          </div>
        </>
      ) : (
        <div className="content-footer">
          <div className="footer-logo">
            <img src={Footer_logo} alt="logo"  width={154} />
          </div>

          <div className="footer-nav">
            <ul className="footer-nav-menu d-flex">
              <NavLink to="/" className="footer-navline1" onClick={scrollToTop}>HOME</NavLink>
              <NavLink to="/men" className="footer-navline1" onClick={scrollToTop}>MEN</NavLink>
              <NavLink to="/women" className="footer-navline2" onClick={scrollToTop}>WOMEN</NavLink>
              <NavLink to="/productlist" className="footer-navline1" onClick={scrollToTop}>PRODUCTS</NavLink>
            </ul>
          </div>

          <div className="footer-social-media">
            <FontAwesomeIcon icon={faFacebook}  />
            <FontAwesomeIcon icon={faInstagram}  />
            <FontAwesomeIcon icon={faTwitter}  />
          </div>

          <div className="footer-policies">
            <ul className="footer-policies-menu">
              <NavLink to="/privacy-policy" className="footer-policy-link" onClick={scrollToTop}>Privacy Policy</NavLink>
              <NavLink to="/return-refund-policy" className="footer-policy-link" onClick={scrollToTop}>Return & Refund Policy</NavLink>
              <NavLink to="/terms-conditions" className="footer-policy-link" onClick={scrollToTop}>Terms and Conditions</NavLink>
              <NavLink to="/shipping-policy" className="footer-policy-link" onClick={scrollToTop}>Shipping Policy</NavLink>
              <NavLink to="/contact-us" className="footer-policy-link" onClick={scrollToTop}>Contact Us</NavLink>
            </ul>
          </div>

          <div className="end-footer">
            <p>&copy; 2024 Tarunika. All Rights Reserved</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Footer;
