import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import "./UserTooltip.css";

const Tooltip = ({ links }) => {
  return (
    <div className="tooltip-container" aria-label="User actions" role="tooltip">
      <FontAwesomeIcon icon={faUser} style={{ fontSize: "20px", cursor: "pointer" }} />
      <div className="tooltip-content">
        {links.map((link, index) => (
          <React.Fragment key={index}>
            {link.onClick ? (
              <div className="tooltip-item" onClick={link.onClick}>
                {link.label}
              </div>
            ) : (
              <Link to={link.to} className="tooltip-item">
                {link.label}
              </Link>
            )}
            {index < links.length - 1 && <div className="tooltip-divider"></div>}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Tooltip;
