import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faShoppingBag, faTimes } from "@fortawesome/free-solid-svg-icons"; 
import logo from "../../Assets/gold.png";
import { useCart } from "../../Context/Cart";
import usericon from "../../Assets/userIcon.png";
import whiteuser from "../../Assets/whiteuser.png";
import Tooltip from "./UserTooltip";
import Fuse from "fuse.js";
import "./Navbar.css";

const searchData = [
  { title: "Home", link: "/" },
  { title: "Men's Clothing", link: "/men" },
  { title: "Women's Clothing", link: "/women" },
  { title: "PRODUCTS", link: "/productlist" },
];

const fuse = new Fuse(searchData, {
  keys: ["title"],
});

const Navbar = ({ cartCount, showNormalNav, textColor }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [menu, setMenu] = useState("home");
  const [searchOpen, setSearchOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [cart] = useCart();
  const [isHidden, setIsHidden] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > lastScrollTop) {
        setIsHidden(true);
      } else {
        setIsHidden(false); 
      }
      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    };

    let lastScrollTop = 0;

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const authData = sessionStorage.getItem("auth");
    setIsLoggedIn(!!authData);
  }, [location]);

  const handleLogout = () => {
    sessionStorage.removeItem("auth");
    sessionStorage.removeItem("token");
    setIsLoggedIn(false);
    navigate("/login");
  };

  const textClass = textColor === "white" ? "whiteText" : "blackText";
  const userIcon = textColor === "white" ? whiteuser : usericon;

  const handleSearch = (event) => {
    const query = event.target.value;
    const results = fuse.search(query);
    setSearchResults(results.map((res) => res.item));
  };

  const userLinks = isLoggedIn
  ? [
    { to: "/dashboard/user/profile", label: "Profile" },
    { to: "/dashboard/user/orderhistory", label: "Orders" },
    { to: "/dashboard/user/wishlist", label: "Wishlist" },
    { to: "#", label: "Logout", onClick: handleLogout },
  ]
  : [{ to: "/login", label: "Login" }];

  return (
    <nav className={`navbar navbar-expand-lg p-0 ${isHidden ? "hide" : ""}`}>
      <div className="container-lg justify-content-around">
        <div className="brand-menu">
          <div className="logo">
            <a className="navbar-brand" href="/">
              <img
                className="m-0"
                src={logo}
                alt=""
                style={{ width: "200px", height: "auto" }}
              />
            </a>
          </div>
          <button
            className="d-lg-none navbar-toggler collapsed d-flex flex-column"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="toggler-icon top-bar"></span>
            <span className="toggler-icon middle-bar"></span>
            <span className="toggler-icon bottom-bar"></span>
          </button>
        </div>
        <div>
          <div className="collapse navbar-collapse custom-nav align-items-center" id="navbarNav">
            <ul className={`navbar-nav ${textClass}`}>
              <li onClick={() => setMenu("home")} className={`nav-item px-1  ${menu === "home" ? "active" : ""}`}>
                <NavLink to="/" className={`nav-link ${textClass}`} aria-current="page">
                  HOME
                </NavLink>
              </li>
              <li onClick={() => setMenu("men")} className={`nav-item px-1 ${menu === "men" ? "active" : ""}`}>
                <NavLink to="/men" className={`nav-link ${textClass}`} aria-current="page">
                  MEN
                </NavLink>
                <ul className="submenu">
                  <li><NavLink to="/men/kurta-pajama">KURTA PAJAMA</NavLink></li>
                  <li><NavLink to="/men/kurta-jacket-sets">KURTA JACKET SETS</NavLink></li>
                  <li><NavLink to="/men/only-kurtas">ONLY KURTAS</NavLink></li>
                  <li><NavLink to="/men/nehru-jacket">NEHRU JACKET</NavLink></li>
                </ul>
              </li>
              <li onClick={() => setMenu("women")} className={`nav-item px-1 ${menu === "women" ? "active" : ""}`}>
                <NavLink to="/women" className={`nav-link ${textClass}`} aria-current="page">
                  WOMEN
                </NavLink>
                <ul className="submenu">
                  <li><NavLink to="/women/kurta-suit-sets">Kurta Suit Sets</NavLink></li>
                  <li><NavLink to="/women/palazzo-sharara-suit-sets">Palazzo / Sharara Suit Sets</NavLink></li>
                </ul>
              </li>
              <li onClick={() => setMenu("product")} className={`nav-item px-1 ${menu === "product" ? "active" : ""}`}>
                <NavLink to="/productlist" className={`nav-link ${textClass}`} aria-current="page">
                  PRODUCTS
                </NavLink>
              </li>
              <div className="d-lg-flex justify-content-around mt-2 nav-right" style={{ gap: "4rem" }}>
                <Tooltip icon={userIcon} links={userLinks} />
                <Link to="/cart">
                  <div className="cart-icon sett">
                    <FontAwesomeIcon icon={faShoppingBag} size="lg" style={{ fontSize: "20px", color: "#ffd404" }} />
                    <span>{cartCount}</span>
                  </div>
                </Link>
                <div className="search-box">
                  <button className="btn-search" onClick={() => setSearchOpen(!searchOpen)}>
                    <FontAwesomeIcon
                      icon={searchOpen ? faTimes : faSearch}
                      style={{ color: searchOpen ? 'white' : '#ffd404' }}
                    />
                  </button>
                  <input
                    type="text"
                    className={`input-search ${searchOpen ? "open" : ""}`}
                    placeholder="Search here..."
                    onChange={handleSearch}
                  />
                  {searchOpen && searchResults.length > 0 && (
                    <div className="search-results">
                      {searchResults.map((result, index) => (
                        <Link key={index} to={result.link}>
                          {result.title}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
