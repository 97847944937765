import React from "react";
import "./CategorySection.css";
import menImage from "../../Assets/Men_category.webp";
import womenImage from "../../Assets/women.jpeg";
import kidsImage from "../../Assets/kid.jpeg";

const CategorySection = () => {
  return (
    <div className="category-section-container">
      <h3 className="section-headingg">Shop by Category</h3>
      <div className="category-section">
        <div className="bubble_item">
          <img src={menImage} alt="Men's Kurtas" className="bubble_image" />
          <div className="bubble_text">Men</div>
        </div>
        <div className="bubble_item">
          <img src={womenImage} alt="Women's Kurtas" className="bubble_image" />
          <div className="bubble_text">Women</div>
        </div>
        <div className="bubble_item">
          <img src={kidsImage} alt="Kids' Kurtas" className="bubble_image" />
          <div className="bubble_text">Kids</div>
        </div>
      </div>
    </div>
  );
};

export default CategorySection;
