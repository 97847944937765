import React, { useState, useEffect } from "react";
import UserMenu from "../../Components/Layout/UserMenu";
import Layout from "./../../Components/Layout/Layout";
import Footer from "../../Components/Layout/Footer";
import axios from "axios";
import moment from "moment";
import "./css/Orders.css";

const Orders = () => {
  const [cartItems, setCartItems] = useState([]);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedOrder, setExpandedOrder] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const ordersPerPage = 2;

  const accessToken = sessionStorage.getItem("auth");

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(
          "https://tarunika.backendapihub.com/order/history",
          {
            headers: {
              accept: "application/json",
              "access-token": accessToken,
            },
          }
        );
        if (response.data.status === "success") {
          const sortedOrders = response.data.data.sort(
            (a, b) => new Date(b.order_date) - new Date(a.order_date)
          );
          setOrders(sortedOrders);
        } else {
          setError(response.data.message);
        }
      } catch (err) {
        setError("An error occurred while fetching orders.");
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const statusColor = (status) => {
    switch (status) {
      case "Delivered":
        return "#28A745";
      case "Created":
        return "#007BFF";
      case "Cancelled":
        return "#6C757D";
      case "Returned":
        return "#FD7E14";
      case "Processing":
        return "#FFC107";
      case "Payment Failed":
        return "#DC3545";
      case "Packed":
        return "#17A2B8";
      default:
        return "#000000";
    }
  };

  const toggleOrderDetails = (orderId) => {
    setExpandedOrder(expandedOrder === orderId ? null : orderId);
  };

  // Pagination: Get the orders for the current page
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);

  // Function to go to the next page
  const nextPage = () => {
    if (currentPage < Math.ceil(orders.length / ordersPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Function to go to the previous page
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems} isNavbar={true}>
      <div className="dash-top-section"></div>
      <div className="dash-container">
        <div className="d-flex flex-lg-nowrap userActflexx">
          <UserMenu />
          <div className="orc">
            <h1 className="text-start p-0">Orders History</h1>
            <div className="orderCardList">
              <div className="itemsContainer">
                {loading ? (
                  <p>Loading...</p>
                ) : error ? (
                  <p>{error}</p>
                ) : orders.length > 0 ? (
                  <>
                    {currentOrders.map((order) => (
                      <div key={order.order_id} className="orderCardItem">
                        <div
                          className="orderSummary"
                          onClick={() => toggleOrderDetails(order.order_id)}
                          style={{ cursor: "pointer" }}
                        >
                          <h3>Order ID: {order.order_id}</h3>
                          <p>
                            Order Date:{" "}
                            {moment(order.order_date).format("YYYY-MM-DD")}
                          </p>
                          <p>Total MRP: ₹ {order.amount}</p>
                          <p>Shipping Charge: ₹ {order.shipping_price}</p>
                          <p>Total Amount: ₹ {order.total_amount.toFixed(2)}</p>
                          <p>
                            Status:{" "}
                            <span
                              style={{ color: statusColor(order.order_status) }}
                            >
                              {order.order_status}
                            </span>
                          </p>
                        </div>

                        {/* Expand product details when clicked */}
                        {expandedOrder === order.order_id && (
                          <div className="productDetails">
                            {order.product_details.map((product, index) => (
                              <div
                                key={`${order.order_id}-${index}`}
                                className="productCardItem d-flex align-items-center"
                              >
                                <hr
                                  style={{
                                    border: "1px solid #ddd",
                                    width: "100%",
                                  }}
                                />
                                <img
                                  src={`https://tarunika.backendapihub.com/images/${product.media_name}`}
                                  alt={product.product_name}
                                  onError={(e) =>
                                    (e.target.src =
                                      "https://s3-us-west-2.amazonaws.com/s.cdpn.io/1255/image-not-found.svg")
                                  } // Fallback for missing images
                                  className="productImage"
                                />
                                <div className="productContent">
                                  <div className="contentOne d-flex flex-wrap align-items-center">
                                    <h4 className="productName">
                                      {product.product_name}
                                    </h4>
                                    <div className="productRightContain d-flex align-items-center">
                                      <div className="productPrice">
                                        <p className="m-0 p-0">Price</p>
                                        <span>
                                          ₹{" "}
                                          {product.product_price?.toFixed(2) ||
                                            "0.00"}
                                        </span>
                                      </div>
                                      <div className="productQuantity">
                                        <p className="m-0 p-0">Quantity</p>
                                        <span>{product.quantity || "0"}</span>
                                      </div>
                                      <div className="productTotal">
                                        <p className="m-0 p-0">Total</p>
                                        <span>
                                          ₹{" "}
                                          {(
                                            product.quantity *
                                            product.product_price
                                          ).toFixed(2)}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <hr className="horizontalLine" />
                                  <div className="contentTwo d-flex align-items-center gap-3">
                                    <p className="m-0">
                                      Category: {product.category_name}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}

                    {/* Pagination Controls */}
                    <div className="pagination">
                      <button
                        onClick={prevPage}
                        disabled={currentPage === 1 || loading}
                        className="pagination-btn"
                      >
                        Previous
                      </button>
                      <span>
                        {loading
                          ? "Loading..."
                          : `Page ${currentPage} of ${Math.ceil(
                              orders.length / ordersPerPage
                            )}`}
                      </span>
                      <button
                        onClick={nextPage}
                        disabled={
                          currentPage ===
                            Math.ceil(orders.length / ordersPerPage) || loading
                        }
                        className="pagination-btn"
                      >
                        Next
                      </button>
                    </div>
                  </>
                ) : (
                  <p>No orders found</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer showBothDivs={false} />
    </Layout>
  );
};

export default Orders;
