import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import '../Styles/PaymentResult.css'; 
import Layout from '../Components/Layout/Layout';
import Footer from '../Components/Layout/Footer';

const PaymentFailurePage = () => {
  const location = useLocation();
  const [cartItems, setCartItems] = useState(location.state?.cartItems || []);
  const [transactionId, setTransactionId] = useState('');
  const [amount, setAmount] = useState('');


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const razorpayPaymentId = searchParams.get('razorpay_payment_id');
    const razorpayAmount = searchParams.get('amount'); 
    
    setTransactionId(razorpayPaymentId); 
    setAmount(razorpayAmount);
  }, [location.search]);

  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems} isNavbar={true}>
       <div className="success-bg row position-relative"></div>
    <div className="containerr">
      <div className="printer-top"></div>

      <div className="paper-container">
        <div className="printer-bottom"></div>

        <div className="paperr">
          <div className="main-contents">
            <div className="failure-icon">&#10060;</div>
            <div className="failure-title">Payment Failed</div>
            <div className="failure-description">
              Unfortunately, your payment could not be processed. Please try again later or contact support.
            </div>
            <div className="order-details">
              <div className="order-number-label">Transaction ID</div>
              <div className="order-number">{transactionId || "N/A"}</div>
              <div className="order-number-label">Amount Attempted</div>
              <div className="order-number">₹ {amount || "N/A"}</div>
              <div className="complement">We apologize for the inconvenience.</div>
            </div>
          </div>
          <div className="jagged-edge"></div>
        </div>
      </div>
    </div>
    <Footer showBothDivs={false} />
    </Layout>
  );
};

export default PaymentFailurePage;
