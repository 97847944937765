import React from "react";
import "./ShopByCategory.css";
import cetegory_1 from "../../Assets/cetegory_1.webp";
import cetegory_2 from "../../Assets/cetegory_2.webp";
import cetegory_3 from "../../Assets/cetegory_5.webp";
import cetegory_4 from "../../Assets/Sub_A-line_women.webp";
import cetegory_5 from "../../Assets/Sub_Anarkali_women.webp";
import cetegory_6 from "../../Assets/Sub_staright_women.webp";


const ShopByCategory = () => {
  return (
    <div className="shop-category-container">
      {/* Men's Kurta Styles Section */}
      <h3 className="category-heading">Explore Men's Kurta Styles</h3>
      <div className="category-grid">
        <div className="image-element">
          <img src={cetegory_1} alt="Casual Kurtas" />
          <div className="image-text">Casual Kurtas</div>
        </div>
        <div className="image-element">
          <img src={cetegory_2} alt="Pathani Kurtas" />
          <div className="image-text">Pathani Kurtas</div>
        </div>
        <div className="image-element">
          <img src={cetegory_3} alt="Kurta Sets" />
          <div className="image-text">Kurta Sets</div>
        </div>
      </div>

      {/* Women's Kurta Collections Section */}
      <h3 className="category-heading">Discover Women's Kurta Collections</h3>
      <div className="category-grid">
        <div className="image-element">
          <img src={cetegory_4} alt="Anarkali Kurtas" />
          <div className="image-text">Anarkali Kurtas</div>
        </div>
        <div className="image-element">
          <img src={cetegory_5} alt="Straight Kurtas" />
          <div className="image-text">Straight Kurtas</div>
        </div>
        <div className="image-element">
          <img src={cetegory_6} alt="A-Line Kurtas" />
          <div className="image-text">A-Line Kurtas</div>
        </div>
      </div>
    </div>
  );
};

export default ShopByCategory;
