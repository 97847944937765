import React, { useState, useEffect, useRef } from "react";
import './UserMenu.css';
import { NavLink, useNavigate, useLocation } from "react-router-dom";

const UserMenu = () => {
  const [selectedOption, setSelectedOption] = useState(''); 
  const navigate = useNavigate();
  const location = useLocation(); 
  const dropdownRef = useRef(null);

  const handleOptionSelect = (option, path, event) => {
    event.preventDefault();
    setSelectedOption(option);
    navigate(path);
  };

  const getButtonLabel = () => {
    return `My Account (${selectedOption || "Select"})`; 
  };

  useEffect(() => {
    const currentPath = location.pathname;

    if (currentPath.includes("/dashboard/user/profile")) {
      setSelectedOption("Profile");
    } else if (currentPath.includes("/dashboard/user/orderhistory")) {
      setSelectedOption("Orders History");
    } else if (currentPath.includes("/dashboard/user/wishlist")) {
      setSelectedOption("Wishlist");
    } else {
      setSelectedOption("Select"); 
    }

  }, [location.pathname]);

  const toggleDropdown = () => {
    const dropdownElement = dropdownRef.current;
    if (dropdownElement) {
      const dropdown = new window.bootstrap.Dropdown(dropdownElement);
      dropdown.toggle();
    }
  };

  return (
    <>
      {/* Large screens */}
      <div className="myAccountBox d-none d-lg-block">
        <h1 className="dashLeftTitle">My Account</h1>
        <div className="list-group list-group-flush">
          <NavLink
            to="/dashboard/user/profile"
            className={`list-group-itemum list-group-item list-group-item-action ${selectedOption === 'Profile' ? 'active' : ''}`}
            onClick={() => setSelectedOption('Profile')}
          >
            Profile
          </NavLink>
          <NavLink
            to="/dashboard/user/orderhistory"
            className={`list-group-itemum list-group-item list-group-item-action ${selectedOption === 'Orders History' ? 'active' : ''}`}
            onClick={() => setSelectedOption('Orders History')}
          >
            Orders History
          </NavLink>
          <NavLink
            to="/dashboard/user/wishlist"
            className={`list-group-itemum list-group-item list-group-item-action ${selectedOption === 'Wishlist' ? 'active' : ''}`}
            onClick={() => setSelectedOption('Wishlist')}
          >
            Wishlist
          </NavLink>
        </div>
      </div>

      {/* Small screens */}
      <div className="userMenubtnClass d-lg-none">
        <div className="dropdown">
          <button
            ref={dropdownRef}
            className="btn-secondary dropdown-toggle drpUserbtnMyAct"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            onClick={toggleDropdown} 
          >
            {getButtonLabel()}
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li>
              <NavLink
                to="/dashboard/user/profile"
                className={`dropdown-item ${selectedOption === 'Profile' ? 'active' : ''}`}
                onClick={(event) => handleOptionSelect("Profile", "/dashboard/user/profile", event)}
              >
                Profile
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/dashboard/user/orderhistory"
                className={`dropdown-item ${selectedOption === 'Orders History' ? 'active' : ''}`}
                onClick={(event) => handleOptionSelect("Orders History", "/dashboard/user/orderhistory", event)}
              >
                Orders History
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/dashboard/user/wishlist"
                className={`dropdown-item ${selectedOption === 'Wishlist' ? 'active' : ''}`}
                onClick={(event) => handleOptionSelect("Wishlist", "/dashboard/user/wishlist", event)}
              >
                Wishlist
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default UserMenu;
