import ViewSimilar from "../Components/Sub_Home/ViewSimilar";
import { useState, useEffect, useRef } from "react";
import Layout from "../Components/Layout/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as farHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as fasHeart } from "@fortawesome/free-solid-svg-icons";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import {
  FacebookShareButton,
  WhatsappShareButton,
  FacebookIcon,
  WhatsappIcon,
} from "react-share";
import defaultImage from "../Assets/image_not_found.svg";
import toast from "react-hot-toast";
import axios from "axios";
import Footer from "../Components/Layout/Footer";
import Modal from "@mui/material/Modal";
import { useParams, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { useWishlist } from "../Context/WishlistContext";
import { IconButton } from "@mui/material";
import "../Styles/ProductItem.css";

export const ProductItem1 = () => {
  const navigate = useNavigate();
  const { slug: id } = useParams();
  const [cartItems, setCartItems] = useState([]);
  const [productData, setProductData] = useState(null);
  const [wishlistClicked, setWishlistClicked] = useState(false);
  const [media, setMedia] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedSize, setSelectedSize] = useState(null);
  const [pincode, setPincode] = useState("");
  const [message, setMessage] = useState("");
  const [apiFailed, setApiFailed] = useState(false);
  const [descriptionDetails, setDescriptionDetails] = useState([]);
  const [activeSection, setActiveSection] = useState("");
  const [selectedColor, setSelectedColor] = useState(null);
  const [productId, setProductId] = useState(id);
  const [wishlistid, setWishlistId] = useState("");
  const [loading, setLoading] = useState(true);
  const { wishlist, removeFromWishlist, addToWishlist } = useWishlist();
  const [openModal, setOpenModal] = useState(false);
  const [fullDescription, setFullDescription] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const [colorKey, setColorKey] = useState("");
  const [sizeKey, setSizeKey] = useState("");



  const shareUrl = window.location.href;

  const contentRef = useRef(null);

  const accessToken = sessionStorage.getItem("auth");

  // const handleOpenModal = (description, key) => {
  //   setFullDescription(description);
  //   setModalTitle(key);
  //   setOpenModal(true);
  // };

  const handleVideoSelect = (mediaSrc, index) => {
    if (currentlyPlaying) {
      currentlyPlaying.pause();
      currentlyPlaying.currentTime = 0;
    }

    const videoElement = document.getElementById(`video-${index}`);

    if (videoElement) {
      videoElement
        .play()
        .then(() => {
          setCurrentlyPlaying(videoElement);
          setSelectedImage(mediaSrc);
        })
        .catch((error) => {
          console.error("Error playing video:", error);
        });
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleColorSelect = (color) => {
    console.log("Selected color:", color);
    setSelectedColor(color);

    const updatedProductId = color.id;
    setProductId(updatedProductId);
    navigate(`/product/${updatedProductId}`);
  };

  const handleSizeSelect = (size) => {
    setSelectedSize(size);

    // console.log("Selected size ID:", size.size_id);

    // const updatedProductId = size.size_id;
    // setProductId(updatedProductId);

    // navigate(`/product/${updatedProductId}`);
  };


  const toggleWishlist = async () => {
    if (!accessToken) {
      toast.error("Please log in to add items to your wishlist.");
      return;
    }
    try {
      if (wishlistClicked) {
        await axios.delete(
          "https://tarunika.backendapihub.com/wishlist/wishlist/remove",
          {
            headers: {
              "access-token": accessToken,
              "Content-Type": "application/json",
            },
            data: { productID: wishlistid },
          }
        );

        removeFromWishlist(wishlistid);
        setWishlistClicked(false);
        toast.success("Item removed from wishlist.");
      } else {
        await axios.post(
          "https://tarunika.backendapihub.com/wishlist/wishlist/add",
          { productvariationID: wishlistid },
          {
            headers: {
              "access-token": accessToken,
            },
          }
        );

        addToWishlist(wishlistid);
        setWishlistClicked(true);
        toast.success("Item added to wishlist.");
      }
    } catch (error) {
      toast.error("This item is already in your wishlist!");
    }
  };

  const fetchCartItems = async () => {
    if (!accessToken) return;
    try {
      const response = await axios.get(
        "https://tarunika.backendapihub.com/cart/show",
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );
      if (response.status === 200) {
        if (Array.isArray(response.data.data)) {
          setCartItems(response.data.data);
        } else {
          console.error("Cart items data is not an array");
          setCartItems([]);
        }
      } else {
        console.error("Failed to fetch cart items");
      }
    } catch (error) {
      console.error("Error fetching cart items:", error);
      setCartItems([]);
    }
  };

  useEffect(() => {
    fetchCartItems();
  }, []);

  const fetchProductDetails = async (productId) => {
    try {
      const response = await axios.get(
        `https://tarunika.backendapihub.com/product/detailview/${productId}`
      );

      const productData = response.data;

      setProductData(productData);
      setWishlistId(productData.data[0].id);

      const descriptionDetailsData = response.data.data[0].description_details;
      try {
        const parsedDetails = JSON.parse(descriptionDetailsData);
        setDescriptionDetails(parsedDetails);

        if (parsedDetails.length > 0) {
          setActiveSection(Object.keys(parsedDetails[0])[0].toLowerCase());
        }
      } catch (error) {
        console.error("Error parsing description details:", error);
      }

      const product = response.data.data[0];

      const colorKey = Object.keys(product).find(
        (key) => key.toLowerCase() === "colour"
      );
      const sizeKey = Object.keys(product).find(
        (key) => key.toLowerCase() === "size"
      );

      // Set the keys dynamically
      setColorKey(colorKey);
      setSizeKey(sizeKey);

      // Media handling
      if (product.media && product.media.length > 0) {
        const mediaUrls = product.media.map((mediaSrc) => {
          return `https://tarunika.backendapihub.com/images/${mediaSrc}`;
        });
        setMedia(mediaUrls);
        setSelectedImage(mediaUrls[0]);
      }

      if (product[colorKey] && product[colorKey].length > 0) {
        const foundColor = product[colorKey].find(
          (s) => s.id === selectedColor?.id
        );
        setSelectedColor(foundColor || product[colorKey][0]);
      }

      if (product[sizeKey] && product[sizeKey].length > 0) {
        setSelectedSize(product[sizeKey][0]);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setApiFailed(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (productId) {
      fetchProductDetails(productId);
    }
  }, [productId]);

  const handleCheckPincode = async () => {
    if (!accessToken) {
      setMessage("Please log in to check delivery options.");
      return;
    }

    if (pincode.length !== 6 || isNaN(pincode)) {
      setMessage("Please enter a valid 6-digit pincode.");
      return;
    }

    try {
      const response = await fetch(
        `https://tarunika.backendapihub.com/address/getPincode?pincode=${pincode}`,
        {
          method: "GET",
          headers: {
            "access-token": accessToken,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (
          data.status.toLowerCase() === "success" &&
          data.message === "Pincode found"
        ) {
          setMessage("Delivery available for this pincode");
        } else {
          setMessage("Unfortunately, we do not ship to your pincode.");
        }
      } else {
        setMessage("Unfortunately, we do not ship to your pincode.");
      }
    } catch (error) {
      console.error("Error fetching pincode:", error);
      setMessage("Error: Unable to fetch data.");
    }
  };

  useEffect(() => {
    const stars = document.querySelectorAll(".star");

    stars.forEach((star, index) => {
      star.addEventListener("click", function () {
        resetStars();
        for (let i = 0; i <= index; i++) {
          stars[i].classList.add("active");
        }
      });
    });

    function resetStars() {
      stars.forEach((star) => {
        star.classList.remove("active");
      });
    }

    return () => {
      stars.forEach((star) => {
        star.removeEventListener("click", resetStars);
      });
    };
  }, []);

  const addToCart = () => {
    if (!accessToken) {
      toast.error("Please log in to add items to your cart.");
      return;
    }
    if (!selectedSize) {
      toast.error("Please select a size.");
      return;
    }
    if (!selectedColor) {
      toast.error("Please select a color.");
      return;
    }

    const data = {
      product_id: productId, // The current product ID
      l1_id: id, // Slug ID from useParams
      size_id: selectedSize.size_id, // Size ID of the selected size
      quantity: 1, // Default quantity as 1
    };

    axios
      .post("https://tarunika.backendapihub.com/cart/add", data, {
        headers: {
          "access-token": accessToken,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message);
          fetchCartItems();
        } else {
          toast.error("Failed to add item to cart.");
        }
      })
      .catch((error) => {
        toast.error("Error adding item to cart.");
      });
  };

  if (loading) {
    return (
      <Layout>
        <div className="loader-container">
          <CircularProgress />
        </div>
      </Layout>
    );
  }

  if (!productData) {
    if (apiFailed) {
      return (
        <Layout>
          <div className="ProductItem p-5">
            <div className="row m-0">
              <div className="col-lg-4 mb-3 mb-lg-0">
                <img src={defaultImage} alt="No images available" />
              </div>
              <div className="col-lg-8 mb-3 mb-lg-0">
                <p>
                  Product details are currently unavailable. please try again
                  later.
                </p>
              </div>
            </div>
          </div>
        </Layout>
      );
    } else {
      return (
        <Layout>
          <div className="ProductItem p-5">
            <div className="row m-0">
              <div className="col-lg-4 mb-3 mb-lg-0">
                <img src={defaultImage} alt="Loading image" />
              </div>
              <div className="col-lg-8 mb-3 mb-lg-0">
                <p>Loading image...</p>
              </div>
            </div>
          </div>
        </Layout>
      );
    }
  }

  const { product_name, sku, description, similar_products } =
    productData.data[0];

  const capitalizeFirstLetter = (str) => {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const price = selectedSize ? selectedSize.price : "N/A";

  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems} isNavbar={true}>
      <div className="item-top-section"></div>
      <section className="product_main">
        <div className="container">
          <div className="product_detail_section">
            <div className="row m-0">
              <div className="col-lg-6 mb-3 mb-lg-0 p-1">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-2 p-2">
                    <div
                      className="d-flex flex-md-column flex-sm-row flex-row justify-content-between"
                      style={{ gap: "10px" }}
                    >
                      {media && media.length > 0 ? (
                        media.map((mediaSrc, index) => {
                          const isVideo = /\.(mp4|webm|ogg)$/i.test(mediaSrc);
                          return (
                            <div
                              key={index}
                              className="media-container"
                              onClick={() => handleVideoSelect(mediaSrc, index)}
                              onMouseEnter={() => setSelectedImage(mediaSrc)}
                            >
                              {isVideo ? (
                                <video
                                  id={`video-${index}`}
                                  src={mediaSrc}
                                  controls
                                  preload="auto"
                                  muted={selectedImage !== mediaSrc}
                                  loop={false}
                                />
                              ) : (
                                <img
                                  src={mediaSrc}
                                  alt={`product-img-${index}`}
                                  className="img-fluid"
                                />
                              )}
                            </div>
                          );
                        })
                      ) : (
                        <img src={defaultImage} alt="No media available" />
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-sm-12 col-md-10 p-2">
                    <div className="d-flex flex-column align-items-center">
                      {selectedImage.endsWith(".mp4") ? (
                        <video
                          className=" img-fluid w-100"
                          src={selectedImage}
                          controls
                          autoPlay
                          loop
                        />
                      ) : (
                        <img
                          className=" img-fluid w-100"
                          src={selectedImage}
                          alt="Product"
                        />
                      )}

                      <div className="d-flex justify-content-between align-items-center">
                        <IconButton color="error">
                          <FontAwesomeIcon
                            icon={wishlistClicked ? fasHeart : farHeart}
                            className={` ${wishlistClicked ? "clicked" : ""}`}
                            onClick={(e) => {
                              e.stopPropagation();
                              toggleWishlist();
                            }}
                          />
                        </IconButton>
                        <IconButton color="success">
                          <FontAwesomeIcon
                            icon={faShareAlt}
                            className=""
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowShareOptions(!showShareOptions);
                            }}
                          />
                        </IconButton>
                        {showShareOptions && (
                          <div className="share-options">
                            <WhatsappShareButton url={shareUrl}>
                              <WhatsappIcon
                                size={32}
                                round={true}
                                className="share-icon"
                              />
                            </WhatsappShareButton>
                            <FacebookShareButton url={shareUrl}>
                              <FacebookIcon
                                size={32}
                                round={true}
                                className="share-icon"
                              />
                            </FacebookShareButton>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 mb-3 mb-lg-0 p-3">
                <div className="small-none py-4" id="jkjk">
                  <div className="content-one">
                    <h1>{product_name}</h1>
                    <h6>
                      SKU : <span>{sku}</span>
                    </h6>
                    <p>{description}</p>
                  </div>

                  <div className="content-two d-flex flex-wrap gap-3 align-items-center my-3">
                    <h6>{capitalizeFirstLetter(colorKey)}</h6>
                    <div className="color-options">
                      {productData.data[0][colorKey] &&
                      productData.data[0][colorKey].length > 0 ? (
                        productData.data[0][colorKey].map((color) => (
                          <a
                            key={color.id}
                            className={`item-btn ${
                              selectedColor &&
                              selectedColor.id === color.id
                                ? "selected"
                                : ""
                            }`}
                            onClick={() => handleColorSelect(color)}
                          >
                            {color.l1_primary_filter}
                          </a>
                        ))
                      ) : (
                        <p>No colors available</p>
                      )}
                    </div>
                  </div>


                  <div className="content-two d-flex flex-wrap gap-3 align-items-center my-1">
                    <h6>{capitalizeFirstLetter(sizeKey)}</h6>
                    <div className="size-options">
                      {productData.data[0][sizeKey] &&
                      productData.data[0][sizeKey].length > 0 ? (
                        productData.data[0][sizeKey].map((variation, index) => (
                          <a
                            key={index}
                            className={`item-btn ${
                              selectedSize?.size_id === variation.size_id
                                ? "selected"
                                : ""
                            }`}
                            onClick={() => handleSizeSelect(variation)}
                          >
                            {variation.size_name}
                          </a>
                        ))
                      ) : (
                        <p>No sizes available</p>
                      )}
                    </div>
                  </div>
                 

                  <div
                    className="content-three d-flex flex-column my-3"
                    id="sdcp"
                  >
                    <span>₹ {price}</span>
                    <p className="m-0">(MRP INCLUSIVE OF ALL TAXES)</p>
                  </div>

                  <div className="pincode-input mb-4 my-3">
                    <label htmlFor="pincode">Delivery Options</label>
                    <div className="input-container">
                      <input
                        type="text"
                        placeholder="Enter Pincode"
                        id="pincode"
                        value={pincode}
                        onChange={(e) => {
                          setPincode(e.target.value);
                          setMessage("");
                        }}
                        className="form-control"
                        maxLength="6"
                      />
                      <button
                        type="button"
                        className="check"
                        onClick={handleCheckPincode}
                      >
                        Check
                      </button>
                    </div>
                    {message && (
                      <p
                        className={`pincode-message ${
                          message.includes("log in")
                            ? "error-message"
                            : message.includes("Delivery available")
                            ? "success-message"
                            : "error-message"
                        }`}
                      >
                        {message}
                      </p>
                    )}
                  </div>

                  <div className="d-flex flex-column gap-2 my-4">
                    <a className="purchase-btn cart-btn" onClick={addToCart}>
                      ADD TO CART
                    </a>
                    {/* <a className="purchase-btn buy-btn">BUY NOW</a> */}
                  </div>

                  <div className="content-four d-flex justify-content-start gap-4 align-items-center">
                    {descriptionDetails.map((detail, index) => {
                      const key = Object.keys(detail)[0];
                      const detailContent = Object.values(detail)[0];
                      return (
                        <h6
                          key={index}
                          id={key.toLowerCase()}
                          className={`open${key.toLowerCase()} m-0 ${
                            activeSection === key.toLowerCase() ? "active" : ""
                          }`}
                          onClick={() => setActiveSection(key.toLowerCase())}
                        >
                          {key}
                        </h6>
                      );
                    })}
                  </div>
                  <hr />
                  <div className="content-four-details mt-4" ref={contentRef}>
                    {descriptionDetails.map((detail, index) => {
                      const key = Object.keys(detail)[0];
                      const detailContent = Object.values(detail)[0];

                      const isList =
                        detailContent.includes("<li>") ||
                        detailContent.includes("<ul>");

                      return (
                        <div
                          key={index}
                          id={key.toLowerCase()}
                          className={`desc ${
                            activeSection === key.toLowerCase() ? "" : "d-none"
                          }`}
                          style={{
                            overflow: "hidden",
                            transition: "height 0.3s ease",
                            fontFamily: "Mulish",
                            fontSize: "16px",
                            fontWeight: "400",
                            lineHeight: " 25.76px",
                            textAlign: "left",
                          }}
                        >
                          {isList ? (
                            <ul
                              dangerouslySetInnerHTML={{
                                __html: detailContent,
                              }}
                            />
                          ) : (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: detailContent,
                              }}
                            />
                          )}

                          {/* Measure content height and show "See More" if needed */}
                          {/* <span
                            className={`read-morre-btn  ${
                              detailContent.length > 158
                                ? "visible"
                                : "invisible"
                            }`}
                            onClick={() => {
                              if (detailContent.length > 158) {
                                handleOpenModal(detailContent, key);
                              }
                            }}
                          >
                            &nbsp;&nbsp;
                            {isExpanded ? "...See Less" : "...See More"}
                            &nbsp;&nbsp;
                          </span> */}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="Best-sellings " id="pdsm">
        {similar_products && similar_products.length > 0 && (
          <SimilarProduct products={similar_products} />
        )}
      </div> */}
      <ViewSimilar/>

      <Modal open={openModal} onClose={handleCloseModal}>
        <div className="modal-content-Description">
          <h2 style={{ textTransform: "capitalize" }}>{modalTitle}</h2>
          <div dangerouslySetInnerHTML={{ __html: fullDescription }} />
          <button onClick={handleCloseModal} className="description-close-btn">
            Close
          </button>
        </div>
      </Modal>

      <Footer showBothDivs={false} />
    </Layout>
  );
};

export default ProductItem1;
