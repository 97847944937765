import React from "react";
import "./TradingCategories.css";
import image1 from "../../Assets/tranding_women1.webp";
import image2 from "../../Assets/tranding_women2.webp";
import image3 from "../../Assets/tranding_women3.webp";
import image4 from "../../Assets/tranding_women4.webp";

const TradingCategories = () => {
  return (
    <div className="trading-categories-container">
      <h3 className="category-heading">New Arrival</h3>
      <div className="category-grid">
        <div className="category-item">
          <img src={image1} alt="Kurta 1" />
          <div className="item-details">
            <p className="item-name">Blue Digital Floral Sharara Suit Set
            </p>
            <p className="item-price">Rs. 4,999.00</p>
          </div>
        </div>
        <div className="category-item">
          <img src={image2} alt="Kurta 2" />
          <div className="item-details">
            <p className="item-name">Black Digital Floral Sharara Suit Set</p>
            <p className="item-price">Rs. 4,999.00</p>
          </div>
        </div>
        <div className="category-item">
          <img src={image3} alt="Kurta 3" />
          <div className="item-details">
            <p className="item-name">Dusty Rose Silk Kurta Suit Set</p>
            <p className="item-price">Rs. 4,999.00</p>
          </div>
        </div>
        <div className="category-item">
          <img src={image4} alt="Kurta 4" />
          <div className="item-details">
            <p className="item-name">Green Cutwork Silk Kurta Suit Set</p>
            <p className="item-price">Rs. 4,999.00</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradingCategories;
