import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Layout from "../Layout/Layout";
import Footer from "../Layout/Footer";
import "./RefundReturnPolicy.css";

const RefundReturnPolicy = () => {
  const location = useLocation();
  const [cartItems, setCartItems] = useState(location.state?.cartItems || []);

  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems} isNavbar={true}>
      <div className="refund-return-policy-container">
        <section className="policy-section">
          <h1 className="policy-title">Refund & Return Policy</h1>

          {/* Return Policy Section */}
          <div className="policy-detail">
            <h2>Return Policy</h2>
            <p>
              Products can be returned within 3 days from the date of delivery.
              Payment Refunds are applicable only in case of damaged products.
              In all other cases, replacement is available.{" "}
            </p>
            <ul>
              <li>
                Returns and Replacements are done free of cost if wrong products
                (incomplete sets or incorrect style), wrong sizes (size other
                than that ordered) or damaged products are received by the
                customer. However, the Company reserves the right to levy
                shipping charges on cases that come under legal scrutiny.
              </li>
              <li>Exchange is allowed with the same or different products.</li>
            </ul>
            <h3>Return Intimation</h3>
            <p>
              You can either call our Support Number <strong>7021307984</strong>{" "}
              or Email Us at{" "}
              <a href="mailto:info@tarunika.in">info@tarunika.in</a> within 3
              days from date of delivery. The details of the return (Order
              number, courier docket No or AWB No, and Reason for Return) should
              be shared with the Customer Support by email.
            </p>
            <p>
              To be eligible for a return, your item must be unused and in the
              same condition that you received it. It must also be in the
              original packaging. To complete your return, we require a receipt
              or proof of purchase.{" "}
            </p>
          </div>

          {/* Refund Process Section */}
          <div className="policy-detail">
            <h2>Refunds (if applicable)</h2>
            <p>
              Once your return is received and inspected, we will send you an
              email to notify you that we have received your returned item. We
              will also notify you of the approval or rejection of your refund.{" "}
            </p>
            <ul>
              <li>
                {" "}
                If you are approved, then your refund will be processed, and a
                credit will automatically be applied to your original method of
                payment, within a certain amount of days.
              </li>
              <li>
                {" "}
                Your refund will be initiated by us within 2 business days of
                receiving the refund request and authorization of the refund.
              </li>
              <li>
                {" "}
                Your refund is estimated to be credited in the account, used for
                order payment, between 4-8 days after initiating the refund.
                Please note that the timing of the actual account credit is
                dependent on multiple external agencies such as banks and
                payment gateways.
              </li>
            </ul>
          </div>

          <div className="policy-detail">
            <h3>Late or Missing Refunds (if applicable)</h3>
            <ul>
              <li>
                If you haven’t received a refund yet, first check your bank
                account again.
              </li>
              <li>
                Then contact your credit card company, it may take some time
                before your refund is officially posted.
              </li>
              <li>
                Next contact your bank. There is often some processing time
                before a refund is posted.
              </li>
              <li>
                If you’ve done all of this and you still have not received your
                refund yet, please contact us at{" "}
                <a href="mailto:info@tarunika.in">info@tarunika.in</a>
              </li>
            </ul>
            <p>
              If you've done all of this and still haven't received your refund,
              please contact us at{" "}
              <a href="mailto:info@tarunika.in">info@tarunika.in</a>.
            </p>
          </div>

          {/* Sale Items Section */}
          <div className="policy-detail">
            <h3>Sale Items (if applicable)</h3>
            <p>
              Only regular priced items may be refunded, unfortunately sale
              items cannot be refunded.
            </p>
          </div>

          {/* Cancellation Policy Section */}
          <div className="policy-detail">
            <h2>Cancellation Policy</h2>
            <p>
              An easy cancellation process is available for all our esteemed
              customers. You can cancel your online order before the product has
              been shipped. Your entire order amount will be refunded.{" "}
            </p>
            <h3>You can cancel an order in an easy step:</h3>
            <ul>
              <li>
                Call us on our support Number <strong>7021307984</strong>.
              </li>
            </ul>
          </div>
        </section>
      </div>
      <Footer showBothDivs={false} />
    </Layout>
  );
};

export default RefundReturnPolicy;
