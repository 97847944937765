import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons';
import { faHeart as fasHeart } from '@fortawesome/free-solid-svg-icons';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import toast from 'react-hot-toast';
import { useWishlist } from '../Context/WishlistContext';
import axios from 'axios';
import imageCompression from 'browser-image-compression';
import { WhatsappShareButton, FacebookShareButton, WhatsappIcon, FacebookIcon } from 'react-share';
import './Item.css';

const defaultImage = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/1255/image-not-found.svg';
const accessToken = sessionStorage.getItem("auth");

const compressImage = async (imageFile) => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 800,
    useWebWorker: true,
  };
  try {
    const compressedFile = await imageCompression(imageFile, options);
    return compressedFile;
  } catch (error) {
    console.error('Error compressing image:', error);
    return imageFile; 
  }
};

export const Item = ({ id, name, desc, img, price, l1, product_id }) => {
  const [wishlistClicked, setWishlistClicked] = useState(false);
  const [imageSrc, setImageSrc] = useState(img);
  const [showShareOptions, setShowShareOptions] = useState(false);
  const navigate = useNavigate();
  const { wishlist = [], addToWishlist, removeFromWishlist } = useWishlist() || {};
  const shareUrl = window.location.href;

  useEffect(() => {
    if (wishlist && wishlist.length) {
      setWishlistClicked(wishlist.includes(product_id));
    }
  }, [wishlist, product_id]);

  useEffect(() => {
    const loadImage = async () => {
      try {
        const response = await fetch(img);
        const blob = await response.blob();
        const compressedImage = await compressImage(blob);
        setImageSrc(URL.createObjectURL(compressedImage));
      } catch (error) {
        console.error('Error loading or compressing image:', error);
        setImageSrc(defaultImage);
      }
    };

    loadImage();
  }, [img]);

  const toggleWishlist = async () => {
    try {
      if (wishlistClicked) {
        await axios.delete('https://tarunika.backendapihub.com/wishlist/wishlist/remove', {
          headers: {
            'access-token': accessToken,
          },
          data: { productID: product_id },
        });

        removeFromWishlist(product_id);
        setWishlistClicked(false);
        toast.success('Item removed from wishlist.');
      } else {
        await axios.post('https://tarunika.backendapihub.com/wishlist/wishlist/add', { productvariationID: product_id }, {
          headers: {
            'access-token': accessToken,
          },
        });

        addToWishlist(product_id);
        setWishlistClicked(true);
        toast.success('Item added to wishlist.');
      }
    } catch (error) {
      toast.error('This item is already in your wishlist!.');
    }
  };

  const goToDetailsPage = () => {
    navigate(`/product/${l1}`);
  };

  return (
    <div className="carrd">
      <div className="card__thumbnail">
        <img src={imageSrc} alt={name} onClick={goToDetailsPage} onError={(e) => e.target.src = defaultImage} />
        <div className="icons-container">
          <FontAwesomeIcon
            icon={wishlistClicked ? fasHeart : farHeart}
            className={`wishlist-icon ${wishlistClicked ? 'text-danger' : 'text-danger'}`} 
            onClick={(e) => {
              e.stopPropagation();
              toggleWishlist();
            }}
          />
          <FontAwesomeIcon
            icon={faShareAlt}
            className={`share-alt-icon ${showShareOptions ? 'text-success' : 'text-success'}`}
            onClick={(e) => {
              e.stopPropagation();
              setShowShareOptions(!showShareOptions);
            }}
          />
          {showShareOptions && (
            <div className="share-optionss">
              <WhatsappShareButton url={shareUrl}>
                <WhatsappIcon size={32} round={true} className="share-iconn" />
              </WhatsappShareButton>
              <FacebookShareButton url={shareUrl}>
                <FacebookIcon size={32} round={true} className="share-iconn" />
              </FacebookShareButton>
            </div>
          )}
        </div>
      </div>
      <div className="card__body">
        <h5 className="card__title" onClick={goToDetailsPage}>{name}</h5>
        <p className="card__desc" onClick={goToDetailsPage}>
          {desc}
        </p>
        <p className="card__price" onClick={goToDetailsPage}>₹ {price}</p>
        <button className="card__button" onClick={goToDetailsPage}>View Details</button>
      </div>
    </div>
  );
};

export default Item;
