import React from 'react'
import Hero from './Hero'
import CategorySection from './CategorySection';
import NewArrivals from './NewArrivals';
import TradingCategories from './TradingCategories';
import FestivalKurtas from './FestivalKurtas';
import ShopByCategory from './ShopByCategory';

const SubHome = () => {
  return (
    <div>
      <Hero />
      <CategorySection />
      {/* <NewArrivals/> */}
      <ShopByCategory />
      <FestivalKurtas />
      <TradingCategories />
    </div>
  )
}

export default SubHome