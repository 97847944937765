import React, { useState, useEffect, useRef } from "react";
import "../Styles/ProductListing.css";
import Item from "../Components/Item";
import filter_icon from "../Assets/filter.png";
import Layout from "../Components/Layout/Layout";
import Footer from "../Components/Layout/Footer";
import { Dropdown } from "react-bootstrap";

const capitalizeWords = (str) => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const accessToken = sessionStorage.getItem("auth");

export const ProductListing = (props) => {
  const [cartItems, setCartItems] = useState([]);
  const [allProduct, setAllProduct] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    colour: [],
    size: [],
    category: [],
    price: [],
  });
  const [selectedFilters, setSelectedFilters] = useState({
    colour: [],
    size: [],
    category: [],
    price: [],
  });

  const [sortBy, setSortBy] = useState("price asc");
  const [isAnyFilterChecked, setIsAnyFilterChecked] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const loaderRef = useRef(null);

  useEffect(() => {
    fetchProducts();
    fetchFilters();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting && !loading) {
          loadMoreProducts();
        }
      },
      { threshold: 1.0 }
    );
    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }
    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [loading]);

  const fetchProducts = async (isLoadMore = false) => {
    try {
      setLoading(true);
      let query = `?page=${page}&limit=10`;

      let filterConditions = [];

      if (selectedFilters.colour.length > 0) {
        const colourCondition = `l1_primary_filter IN (${selectedFilters.colour
          .map((c) => `'${c}'`)
          .join(", ")})`;
        filterConditions.push(colourCondition);
      }

      if (selectedFilters.size.length > 0) {
        const sizeCondition = `l2_primary_filter IN (${selectedFilters.size
          .map((s) => `'${s}'`)
          .join(", ")})`;
        filterConditions.push(sizeCondition);
      }

      if (selectedFilters.category.length > 0) {
        const categoryCondition = `category_id IN (${selectedFilters.category
          .map((c) => `'${c}'`)
          .join(", ")})`;
        filterConditions.push(categoryCondition);
      }

      if (selectedFilters.price.length > 0) {
        const [minPrice, maxPrice] = selectedFilters.price[0]?.range || [
          0, 999999,
        ];
        filterConditions.push(`price >= ${minPrice} AND price <= ${maxPrice}`);
      } else {
        filterConditions.push(`price >= 0 AND price <= 999999`);
      }

      if (filterConditions.length > 0) {
        query = `?filter=${filterConditions.join(" AND ")}`;
      }

      const response = await fetch(
        `https://tarunika.backendapihub.com/product/listview/products${query}`,
        {
          method: "GET",
          headers: {
            "access-token": accessToken,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error! Status: ${response.status}`);
      }

      const result = await response.json();
      const data = result.data;

      const products = data.map((product) => ({
        category_id: product.category_id,
        product_id: product.product_id,
        name: product.product_name || "Unknown Product",
        desc: product.product_description || "No description available",
        l1: product.l1_id || "No id",
        size: product.size_id || "No available size",
        image: product.image
          ? `https://tarunika.backendapihub.com/images/${product.image}`
          : "default_image_url",
        price: product.price !== null ? product.price.toString() : "0.00",
        ram: product.l1_primary_filter,
        memory: product.l2_primary_filter,
      }));

      // Apply sorting after fetching products
      let sortedProducts = products;
      if (sortBy === "price desc") {
        sortedProducts.sort(
          (a, b) => formatPrice(b.price) - formatPrice(a.price)
        );
      } else if (sortBy === "price asc") {
        sortedProducts.sort(
          (a, b) => formatPrice(a.price) - formatPrice(b.price)
        );
      }

      // Remove duplicates
      const uniqueProducts = Array.from(
        new Set(sortedProducts.map((p) => p.product_id))
      ).map((id) => sortedProducts.find((p) => p.product_id === id));

      setAllProduct(uniqueProducts);
      setFilteredProducts(uniqueProducts);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const loadMoreProducts = () => {
    setPage((prev) => prev + 1);
    fetchProducts(true);
  };

  // const filterOpen = () => {
  //   const filterAccordion = document.getElementById("filterAccordion");
  //   if (filterAccordion) {
  //     const isHidden = filterAccordion.style.display === "none";
  //     filterAccordion.style.display = isHidden ? "block" : "none";
  //   } else {
  //     console.error("Element with id 'filterAccordion' not found.");
  //   }
  // };

  const filterOpen = (event) => {
    event.preventDefault(); // Prevent the default anchor behavior
    const filterAccordion = document.getElementById("filterAccordion");
    if (filterAccordion) {
      const isHidden = filterAccordion.style.display === "none";
      filterAccordion.style.display = isHidden ? "block" : "none";
    }
  };
  
  

  const fetchFilters = async () => {
    try {
      const response = await fetch(
        "https://tarunika.backendapihub.com/product/filter?categoryID=3",
        {
          method: "GET",
          headers: {
            "access-token": accessToken,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error! Status: ${response.status}`);
      }

      const result = await response.json();
      const data = result.data[0];

      const filters = {
        colour: data.filter_data.colour || [],
        size: data.filter_data.size || [],
        category: data.category || [],
        price: [
          {
            value: `₹${data.initial_price || 0} - ₹${data.end_price || 10000}`,
            range: [data.initial_price || 0, data.end_price || 10000],
          },
        ],
      };

      filters.category = data.category.map((category) => ({
        value: category,
      }));

      setFilterOptions(filters);
    } catch (error) {
      console.error("Error fetching filters:", error);
      setFilterOptions({
        colour: [],
        size: [],
        category: [],
        price: [],
      });
    }
  };

  const handleFilterChange = (category, value) => {
    setSelectedFilters((prev) => {
      const isSelected = prev[category]?.includes(value);
      const updatedCategory = isSelected
        ? prev[category].filter((item) => item !== value)
        : [...(prev[category] || []), value];

      const updatedFilters = { ...prev, [category]: updatedCategory };

      const anyFilterChecked = Object.values(updatedFilters).some(
        (filters) => filters.length > 0
      );
      setIsAnyFilterChecked(anyFilterChecked);

      filterProducts(updatedFilters);

      return updatedFilters;
    });
  };

  const filterProducts = () => {
    let filtered = [...allProduct];

    // Apply category filter
    if (selectedFilters.category.length > 0) {
      filtered = filtered.filter((product) =>
        selectedFilters.category.includes(product.category_id)
      );
    }

    // Apply Colour filter
    if (selectedFilters.colour.length > 0) {
      filtered = filtered.filter((product) =>
        selectedFilters.colour.includes(product.ram)
      );
    }

    // Apply Size filter
    if (selectedFilters.size.length > 0) {
      filtered = filtered.filter((product) =>
        selectedFilters.size.includes(product.size)
      );
    }

    // Apply Price filter
    if (selectedFilters.price.length > 0) {
      const [minPrice, maxPrice] = selectedFilters.price[0]?.range || [
        0, 999999,
      ];
      filtered = filtered.filter((product) => {
        const productPrice = formatPrice(product.price);
        return productPrice >= minPrice && productPrice <= maxPrice;
      });
    }

    // Set filtered products and handle message visibility
    const uniqueFilteredProducts = Array.from(
      new Set(filtered.map((p) => p.product_id))
    ).map((id) => filtered.find((p) => p.product_id === id));

    setFilteredProducts(uniqueFilteredProducts);
    setIsAnyFilterChecked(
      Object.values(selectedFilters).some((filters) => filters.length > 0)
    );
  };

  const clearAllFilters = () => {
    setSelectedFilters({
      colour: [],
      size: [],
      category: [],
      price: [],
    });

    setSortBy("price asc");
    setPage(1);
    fetchProducts();

    setIsAnyFilterChecked(false);

    document.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
      checkbox.checked = false;
    });
  };

  useEffect(() => {
    fetchProducts();
  }, [selectedFilters]);

  useEffect(() => {
    handleSortChange(sortBy);
  }, [sortBy]);

  useEffect(() => {
    filterProducts();
  }, [selectedFilters]);

  const formatPrice = (price) => {
    const parsedPrice = parseFloat(price.replace(/[^0-9.]/g, ""));
    return isNaN(parsedPrice) ? 0 : parsedPrice;
  };

  const handleSortChange = (sortOption) => {
    if (sortOption === sortBy) return;

    setSortBy(sortOption);
    setFilteredProducts((prevProducts) => {
      let sortedProducts = [...prevProducts];
      if (sortOption === "price desc") {
        sortedProducts.sort(
          (a, b) => formatPrice(b.price) - formatPrice(a.price)
        );
      } else if (sortOption === "price asc") {
        sortedProducts.sort(
          (a, b) => formatPrice(a.price) - formatPrice(b.price)
        );
      }

      return sortedProducts;
    });
  };

  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems} isNavbar={true}>
      <div className="top-section"></div>
      <div className="product-listing">
        <div className="productlisting-header container d-flex justify-content-center align-items-center flex-column">
          <h6>CELEBRATE THE FESTIVE SPIRIT IN STYLE</h6>
          <h2>All products</h2>
        </div>
      </div>
      {/* <div className="productlisting-indexSort fspace filterspc container d-flex align-items-center justify-content-lg-between justify-content-sm-end"> */}
      <div className="productlisting-indexSort container d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between  mb-3">
        <h6 className="filter">FILTERS</h6>
        <button
          className="clear-all-btn btn"
          onClick={clearAllFilters}
          style={{ display: isAnyFilterChecked ? "block" : "none" }}
        >
          Clear All
        </button>
        <div className="productlisting-sort">
          {/* <div className="dropdownsort"> */}
          <div className="btn-group custom-btn-grop">
            <Dropdown>
              <Dropdown.Toggle className="btn custom-btn1" id="sortDropdown">
                Sort by:{" "}
                {sortBy === "price desc" ? "High to Low" : "Low to High"}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleSortChange("price desc")}>
                  Price: High to Low
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleSortChange("price asc")}>
                  Price: Low to High
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <a
              className="btn filter-btn ms-2"
              href="#"
              role="button"
              onClick={(e) => filterOpen(e)}
            >
              <img src={filter_icon} alt="f-icon" />
            </a>
          </div>
          {/* </div> */}
        </div>

        <h6 className="filterIcon d-none">icon</h6>
      </div>
      <div className="product-list">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-12 d-none d-lg-block">
              <div className="accordion" id="filterAccordion" >
                {Object.keys(filterOptions).map((filterKey, index) => (
                  <div className="accordion-item" key={index}>
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#panelsStayOpen-collapse-${index}`}
                        aria-expanded="true"
                        aria-controls={`panelsStayOpen-collapse-${index}`}
                      >
                        {capitalizeWords(filterKey)}
                      </button>
                    </h2>
                    <div
                      id={`panelsStayOpen-collapse-${index}`}
                      className="accordion-collapse collapse show"
                    >
                      <div className="accordion-body">
                        <ul className="list-unstyled">
                          {filterOptions[filterKey]?.map((item, i) => (
                            <li key={i} className="mb-3">
                              <input
                                className="form-check-input me-3"
                                type="checkbox"
                                value={item.value}
                                id={`${filterKey}Checkbox-${i}`}
                                checked={selectedFilters[filterKey]?.includes(
                                  item.value
                                )}
                                onChange={() =>
                                  handleFilterChange(filterKey, item.value)
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`${filterKey}Checkbox-${i}`}
                              >
                                {item.value}
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-9 col-md-12 ">
              <div
                className="row"
                // id="pds"
              >
                {filteredProducts.length > 0
                  ? filteredProducts.map((product) => (
                      <div
                        key={product.product_id}
                        className="col-lg-4 col-md-6 mb-4"
                      >
                        <Item
                          {...product}
                          // category_id={product.category_id}
                          // product_id={product.product_id}
                          // name={product.name}
                          // desc={product.desc}
                          img={product.image}
                          // price={product.price}
                          // l1={product.l1}
                          // size={product.size}
                          // productData={product}
                          setCartItems={setCartItems}
                          cartItems={cartItems}
                        />
                      </div>
                    ))
                  : allProduct.map((product) => (
                      <div
                        key={product.product_id}
                        className="col-lg-4 col-md-6 mb-4"
                      >
                        <Item
                          {...product}
                          // category_id={product.category_id}
                          // product_id={product.product_id}
                          // name={product.name}
                          // desc={product.desc}
                          img={product.image}
                          // price={product.price}
                          // l1={product.l1}
                          // size={product.size}
                          // productData={product}
                          setCartItems={setCartItems}
                          cartItems={cartItems}
                        />
                      </div>
                    ))}
              </div>
              {/* {loading && <p className="text-center">Loading more products...</p>} */}
              <div ref={loaderRef}></div>
            </div>
          </div>
        </div>
      </div>
       
      <Footer showBothDivs={false} />
    </Layout>
  );
};

export default ProductListing;
