import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom'; 
import '../Styles/PaymentResult.css';
import Layout from '../Components/Layout/Layout';
import Footer from '../Components/Layout/Footer';

const PaymentSuccessPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState(location.state?.cartItems || []);
  const [transactionId, setTransactionId] = useState('');
  const [amount, setAmount] = useState('');
  const [timer, setTimer] = useState(10); 
  
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const razorpayPaymentId = searchParams.get('razorpay_payment_id');
    const razorpayAmount = searchParams.get('amount'); 
    
    setTransactionId(razorpayPaymentId); 
    setAmount(razorpayAmount);
  }, [location.search]);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer(prev => prev - 1);
    }, 1000);

    const redirectTimeout = setTimeout(() => {
      navigate('/dashboard/user/orderhistory');  
    }, 10000);

    return () => {
      clearInterval(countdown);
      clearTimeout(redirectTimeout);
    };
  }, [navigate]);

  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems} isNavbar={true}>
      <div className="success-bg row position-relative"></div>
      <div className="containerr">
        <div className="printer-top"></div>
        <div className="paper-container">
          <div className="printer-bottom"></div>
          <div className="paperr">
            <div className="main-contents">
            <div className="redirect-message">
                {timer}
              </div>
              <div className="success-icon">&#10004;</div>
              <div className="success-title">Payment Complete</div>
              <div className="success-description">
                Thank you for completing the payment! 
              </div>
              <div className="order-details">
                <div className="order-number-label">Transaction ID</div>
                <div className="order-number">{transactionId || "N/A"}</div> 
                {/* <div className="order-number-label">Amount Paid</div>
                <div className="order-number">₹ {amount || "N/A"}</div>  */}
                <div className="complement">Thank You!</div>
              </div>
              
            </div>
            <div className="jagged-edge"></div>
          </div>
        </div>
      </div>
      <Footer showBothDivs={false} />
    </Layout>
  );
};

export default PaymentSuccessPage;
