import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import Layout from "../Layout/Layout";
import Footer from "../Layout/Footer";
import "./ShippingPolicy.css";

const ShippingPolicy = () => {
    const location = useLocation();
    const [cartItems, setCartItems] = useState(location.state?.cartItems || []);

  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems} isNavbar={true}>
      <Container fluid className="shipping-policy-container">
        <h1 className="text-center mb-4">Shipping Policy</h1>
        <p>
          We recognize how crucial timely and secure delivery is, which is why we
          collaborate with leading courier services, both domestic and
          international. This enables us to ensure reliable and efficient delivery
          for all your orders.
        </p>

        <p>
        If you have any questions or need assistance, our customer support team is always available to help. Customer satisfaction is our top priority.
        </p>

        <p>
       <a href="tarunika.in">tarunika.in</a> offers free shipping within India on all products offered. We pay for shipping, octroi and other taxes. You only pay the value of the ordered product.
        </p>
        <p>International Shipping is also available on <a href="tarunika.in">tarunika.in</a></p>
        <p>A flat rate of $25 applies to international orders.</p>

        <h2 className="mt-4">Processing Your Order</h2>
        <p>
          We try our best to ship goods to you as soon as possible. On average, it
          takes us about 24 hours to ship goods out of our warehouses across India.
          However, if the quantities ordered are more than 5 pieces per product per
          style, it may take longer to process your order.
        </p>

        <h2 className="mt-4">Customs and International Shipping</h2>
        <p>
          All of our orders are shipped internationally from our warehouse to your
          provided shipping address via DHL. Due to the nature of international
          shipping, occasionally a customer may have to pay additional import duties
          and taxes, which are levied once a shipment reaches your country. The
          majority of orders will not have to pay any additional fees. However, we
          are unable to calculate when and how much these infrequent customs duty
          charges will be levied. In cases where additional customs charges are
          assessed, you will be responsible for paying these fees.
        </p>
      </Container>
      <Footer showBothDivs={false} />
    </Layout>
  );
};

export default ShippingPolicy;
