import React from "react";
import "./FestivalKurtas.css";
import kurta1 from "../../Assets/tranding_man1.webp";
import kurta2 from "../../Assets/tranding_man2.webp";
import kurta3 from "../../Assets/tranding_man3.webp";
import kurta4 from "../../Assets/tranding_man4.webp";

const FestivalKurtas = () => {
  return (
    <div className="festival-kurtas-container">
      <h3 className="section-heading">Festival Kurtas</h3>
      <div className="kurtas-grid">
        <div className="kurta-item">
          <img src={kurta1} alt="Kurta 1" />
          <div className="kurta-details">
            <p className="kurta-name">Embroidered Pure Cotton Straight Kurta</p>
            <p className="kurta-price">Rs. 1,848.00</p>
          </div>
        </div>
        <div className="kurta-item">
          <img src={kurta2} alt="Kurta 2" />
          <div className="kurta-details">
            <p className="kurta-name">Pure Silk Kurta & Pyjama with Woven Jacquard Nehru Jacket</p>
            <p className="kurta-price">Rs. 2,999.00</p>
          </div>
        </div>
        <div className="kurta-item">
          <img src={kurta3} alt="Kurta 3" />
          <div className="kurta-details">
            <p className="kurta-name">Striped Zari Woven Straight Silk Kurta</p>
            <p className="kurta-price">Rs. 999.00</p>
          </div>
        </div>
        <div className="kurta-item">
          <img src={kurta4} alt="Kurta 4" />
          <div className="kurta-details">
            <p className="kurta-name">Thread & Sequin Worked Pure Cotton Straight Kurta</p>
            <p className="kurta-price">Rs. 1,499.00</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FestivalKurtas;
