import React, { useState, useEffect } from "react";
import UserMenu from "../../Components/Layout/UserMenu";
import Layout from "./../../Components/Layout/Layout";
import Footer from "../../Components/Layout/Footer";
import axios from "axios";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useWishlist } from "../../Context/WishlistContext";
import "./css/Wishlist.css";

const Wishlist = () => {
  const [cartItems, setCartItems] = useState([]);
  const [wishlistItems, setWishlistItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { removeFromWishlist: removeFromContextWishlist } = useWishlist(); 
  const accessToken = sessionStorage.getItem("auth");


  const defaultImage =
    "https://s3-us-west-2.amazonaws.com/s.cdpn.io/1255/image-not-found.svg";

  const fetchWishlist = async () => {
    try {
      const response = await axios.get(
        "https://tarunika.backendapihub.com/wishlist/wishlist/view",
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );
      if (response.status === 200) {
        setWishlistItems(response.data.data || []);
      } else {
        setError("Failed to fetch wishlist");
        setWishlistItems([]);
      }
    } catch (error) {
      setError("An error occurred while fetching the wishlist");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWishlist();
  }, []);

  const handleImageError = (e) => {
    e.target.src = defaultImage;
  };

  const addToCart = (l1_id, size) => {
    const quantity = 1;
    const size_id = size;
  
    axios
      .post(
        "https://tarunika.backendapihub.com/cart/add",
        { l1_id, size_id, quantity },
        {
          headers: {
            "access-token": accessToken,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message);
          fetchCartItems();
        } else {
          toast.error("Failed to add item to cart.");
        }
      })
      .catch((error) => {
        toast.error("Error adding item to cart.");
      });
  };

  const fetchCartItems = async () => {
    try {
      const response = await axios.get(
        "https://tarunika.backendapihub.com/cart/show",
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );
      if (response.status === 200) {
        if (Array.isArray(response.data.data)) {
          setCartItems(response.data.data);
        } else {
          console.error("Cart items data is not an array:", response.data.data);
          setCartItems([]);
        }
      } else {
        console.error("Failed to fetch cart items");
      }
    } catch (error) {
      console.error("Error fetching cart items:", error);
      setCartItems && setCartItems([]);
    }
  };
  

  const removeFromWishlist = async (id) => { 
    try {
      const response = await axios({
        method: "delete",
        url: "https://tarunika.backendapihub.com/wishlist/wishlist/remove",
        headers: {
          "access-token": accessToken,
          "Content-Type": "application/json",
        },
        data: { productID: id },
      });

      if (response.status === 200) {
        toast.success(response.data.message);
        setWishlistItems(wishlistItems.filter((item) => item.id !== id));
        removeFromContextWishlist(id);
      } else {
        toast.error("Failed to remove item from wishlist.");
      }
    } catch (error) {
      toast.error("Error removing item from wishlist.");
      if (error.response) {
        console.log("Error response status:", error.response.status);
        console.log("Error response data:", error.response.data);
      } else {
        console.log("Error message:", error.message);
      }
    }
  };

  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems} isNavbar={true}>
      <div className="dash-top-section"></div>
      <div className="dash-container">
        <div className="d-flex flex-lg-nowrap userActflexx">
          <UserMenu />
          <div className="w-containt">
            <h1 className="text-start">Wishlist</h1>
            {loading ? (
              <p>Loading...</p>
            ) : error ? (
              <p style={{ paddingLeft: "60px" }}>{error}</p>
            ) : (
              <div className="scrollable-container">
                <div className="productlisting-products-wish d-flex flex-wrap row-gap-2">
                  {wishlistItems.length > 0 ? (
                    wishlistItems.map((item, i) => (
                      <div
                        className="product-item col-lg-4 col-md-6 mb-4"
                        key={i}
                        id={item.id}
                      >
                        <img
                          className="productt-img"
                          src={`https://tarunika.backendapihub.com/images/${item.image}`}
                          alt={item.product_name}
                          onError={handleImageError}
                        />
                        <div className="product-name">{item.product_name}</div>
                        <div className="product-desc">{item.product_description}</div>
                        <div className="product-price">₹ {item.price}</div>
                        <button
                        className="card__buttonn"
                        onClick={() => addToCart (item.l1_id, item.size)}
                        >
                        ADD TO CART
                        </button> 
                        <button
                          className="close-icon"
                          onClick={() => removeFromWishlist(item.id)}
                        >
                          <FontAwesomeIcon icon={faXmark} />
                        </button>
                      </div>
                    ))
                  ) : (
                    <p>Your wishlist is lonely and looking for love.</p>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer showBothDivs={false} />
    </Layout>
  );
};

export default Wishlist;
